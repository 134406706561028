import React from 'react';
import {Outlet} from 'react-router-dom';
import ChangeLogs from '../components/data/ChangeLog';
import { useSearchParams } from "react-router-dom";


const ChangeLog = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    var index = searchParams.get("i");
    return (
        <div> 
            <section className="intro-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> 
                           <div style={{padding:"30px 0px"}}>
                                <h1 className="title" style={{display:"block",color:"#F29423",fontSize:"50px"}}>{ChangeLogs.items[index].title}</h1>
                                <div style={{textAlign:"left",fontFamily:"Arial"}}>
                                    <span className='ChangeLogDate'>{ChangeLogs.items[index].date}</span>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>                
                
            </section>
            <section className="blog-section spad">
                <div className="container">
                    <div className="row align-items-center">
                        {ChangeLogs.items[index].info()}
                    </div>
                </div>
                <Outlet />
            </section>
        </div>        
    );
};

export default ChangeLog;