const Announcements = {
    items : [
        {
            title:"Announcements",
            date:"19-07-2024",
            Text:"Stay Tuned! 1.5 is comming soon! Be ready for some good add on to the city!"
        },{
            title:"Announcements",
            date:"17-11-2023",
            Text:"We will be screening The Crazies at Vespucci cinema 2h before the AU Wave! New Updated Rules!"
        },{
            title:"Announcements",
            date:"18-09-2023",
            Text:"We are welcoming all new and old players to the shiny and new Gomorrah! Come and chose your destiny!"
        },
        {
            title:"Announcements",
            date:"12-09-2023",
            Text:"This Friday Guy we are having Police Academy! Future State Trooper and Ranger are welcome!"
        },
        {
            title:"Announcements",
            date:"24-08-2023",
            Text:"Still a bit of time to wait but the opening is getting closer! Get ready guys!"
        },
        {
            title:"Announcements",
            date:"27-07-2023",
            Text:"We are currently close for a few weeks. The city is going under construction to make it even better! Re-launch date to come! "
        },
        {
            title:"Announcements",
            date:"",
            Text:"Stay tuned for new event and development on the server!"
        }
    ]    
};

export default Announcements;