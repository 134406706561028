import React from 'react';
import {Outlet} from 'react-router-dom';

const documents = () => {
    return (
        <div> 
            <section className="intro-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> <div style={{padding:"30px 0px"}}><h1 className="title" style={{display:"block",color:"#F29423",fontSize:"50px"}}>Important Documents For Gomorrah:</h1></div></div>
                    </div>                    
                </div>                                
            </section>
            <section className="blog-section spad">
                <div className="container">              
                    <div className="row">
                        <div className="col-md-5 col-lg-6" style={{textAlign:"Left"}}>
                            <p style={{color:"#ffffff"}}>Government Issued Documents:</p>
                            <ul style={{listStyle:"none",paddingBottom:"30px"}}>
                                <li style={{padding:"10px 0"}}><a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"100%",maxWidth:"300px",display:"block"}} href="https://docs.google.com/document/d/1_i0s6fF8z3FRrOhunxiUJ-OUH79FmVa6nDh91PRG4Gc/edit?usp=sharing"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>Business Guidelines</span></a></li>
                                <li style={{padding:"10px 0"}}><a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"100%",maxWidth:"300px",display:"block"}} href="https://forms.gle/VG8YhWV7bHvhWsoK8"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>Business Application Form</span></a></li>
                                <li style={{padding:"10px 0"}}><a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"100%",maxWidth:"300px",display:"block"}} href="https://forms.gle/Qqmja9Cfe6Y9mFXu6"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>Ban Appeal Form</span></a></li>
                                <li style={{padding:"10px 0"}}><a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"100%",maxWidth:"300px",display:"block"}} href="https://forms.gle/QowMP6sbigcE9UWZ6"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>Gangs/MCs Applications</span></a></li> 
                                <li style={{padding:"10px 0"}}><a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"100%",maxWidth:"300px",display:"block"}} href="https://forms.gle/xZce6fgpuLZ7VTDJ9"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>PD Applications</span></a></li>
                                <li style={{padding:"10px 0"}}><a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"100%",maxWidth:"300px",display:"block"}} href="https://forms.gle/8yjZZDq2zJ7kF2L5A"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>EMS Applications</span></a></li>
                                {/* <li style={{padding:"10px 0"}}><a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"100%",maxWidth:"300px",display:"block"}} href="https://docs.google.com/forms/d/1HeBoPCDQKTgWF8PNVXNp_SCZtx5EJGfXlAv5QE4vUgA/edit?pli=1"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>County Clerk Application</span></a></li> */}
                            </ul>
                        </div>
                        <div className="col-md-7 col-lg-6" style={{padding:"10% 0px"}}>
                            <img src="./imgs/smokey.png" className="w-100 img-thumbnail mb-3"/>
                        </div>
                    </div>
                </div>
            </section>
            <Outlet />
        </div>        
    );
};

export default documents;