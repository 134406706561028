const Evenments = {
    items : [
        {
            title:"Movie Night",
            start:"2023-11-17 22:00:00",
            end:"2023-11-17 23:59:00",
            allDay:false,
            resource:null
        },{
            title:"Grand Opening",
            start:"2023-09-17 14:00:00",
            end:"2023-09-17 23:59:00",
            allDay:false,
            resource:null
        },
        {
            title:"Police Academy",
            start:"2023-09-15 19:00:00",
            end:"2023-09-15 23:00:00",
            allDay:false,
            resource:null
        },
        {
            title:"Patch Update",
            start:"2023-05-10 01:00:00",
            end:"2023-05-10 02:00:00",
            allDay:false,
            resource:null
        },
        {
            title:"Server launch From 12am to 1am",
            start:"2023-03-01 00:00:00",
            end:"2023-03-01 01:00:00",
            allDay:false,
            resource:null
        }
    ]    
};

export default Evenments;