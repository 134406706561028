const NewReleases = {
    items : [ 
        {
            title:"🎊 We are live! 🎊",
            date:"2023-09-17",
            descriptionLine1:"The wait is over!",
            descriptionLine2:"Wait stop everything! Gomorrah's door are finally Opened!!",
            completionText:"17th September 2023"
        },
        {
            title:"👮 Police Academy 👮‍♀️",
            date:"2023-09-15",
            descriptionLine1:"We are training Trooper and Ranger this Friday!",
            descriptionLine2:"If you are interested in the Law Enforcement life you are welcome to the police academy! Join discord and let us know your interest!",
            completionText:"15th September 2023 at 19H central time"
        },
        {
            title:"🔨Under construction🔨",
            date:"2023-09-15",
            descriptionLine1:"The city is going under construction",
            descriptionLine2:"We are upgrading the city and we need a few more weeks to give you the best experience we can! The wait is going to be worth it! Thanks for you understanding!",
            completionText:"24th August 2023"
        },    
        {
            title:"🔨Under construction🔨",
            date:"2023-09-15",
            descriptionLine1:"The city is going under construction",
            descriptionLine2:"We are upgrading the city and will be close for a few weeks!",
            completionText:"27th Jully 2023"
        },      
        {
            title:"New Patch Update",
            date:"2023-05-10",
            descriptionLine1:"New mini games and clothing!",
            descriptionLine2:"We have now EMS also in the city! You can apply for the job too!",
            completionText:"May 10th 2023"
        },    
        {
            title:"New release",
            date:"2023-03-01",
            descriptionLine1:"Projected Official Server Launch",
            descriptionLine2:"March 1st 2023",
            completionText:"We are live!!"
        }
    ]    
};

export default NewReleases;