import React from 'react';
import {Outlet} from 'react-router-dom';

const Rules = () => {
    return (
        <div> 
            <section className="intro-section">
                <div className="container">
                    <div className="row">/p
                        <div className="col-md-12"> <div style={{padding:"30px 0px"}}><h1 className="title" style={{display:"block",color:"#ffffff",fontSize:"50px"}}>General Rules</h1></div></div>
                    </div>                    
                </div>                                
            </section>
            <section className="blog-section spad">
                <div className="container">
                    <div>
                        <ul style={{textAlign:"left",display:"block"}}>
                            <li><span style={{padding:"10px 0px",color:"#ffffff"}}>You MUST be 18+ to join and play</span ></li>
                            <li><span style={{padding:"10px 0px",color:"#ffffff"}}>Have a working quality mic</span ></li>
                            <li><span style={{padding:"10px 0px",color:"#ffffff"}}>Have a working quality mic</span ></li>
                            <li><span style={{padding:"5px 0px",color:"#ffffff"}}>Your character needs to have a realistic name and adhere to Twitch Terms of Service (TOS) at all times.  <br/>If you are unware or unsure on these rules please follow this: <a href="https://www.twitch.tv/span /en/legal/terms-of-service/">https://www.twitch.tv/span /en/legal/terms-of-service/</a></span ></li>
                            <li><span style={{padding:"5px 0px",color:"#ffffff"}}>Do not use 3rd party applications to communicate while in the server actively RPing <br/>(I.E do not be in a discord call while in city, that is considered a breach of rules because you are not RPing IN the city at that point)</span ></li>
                            <li><span style={{padding:"5px 0px",color:"#ffffff"}}>Follow the procedures of applying for a gang through the applications in the forms section on the website.</span ></li>
                            <li><span style={{padding:"5px 0px",color:"#ffffff"}}>Toxicity of ANY kind WILL NOT be tolerated at all.  <br/>(This includes in the discord or any other platform that Gomorrah exists on as well as anything Racist, Homophobic or otherwise)</span ></li>
                            <li><span style={{padding:"5px 0px",color:"#ffffff"}}>Being on mostly familiar terms used widely in the city:</span >
                                <ul style={{marginLeft:"20px"}}>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>OOC</span> - Out Of Character</span ></li>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>IC</span> - In Character</span ></li>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Tsunami</span> - Server Restarts</span ></li>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Eyes Open</span> (also sometimes referred to as having voices/demons in their head) - This person is likely streaming or being watched by someone else.</span ></li>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Discordia/Emails</span> - talking about discord messages (this should only be used sparingly for important things seeing as it is an OOC source. AS MUCH information as possible should be relayed through the city NOT your discords</span ></li>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>In Head</span> - Someone may say "Hey, I'm going to be in my head for a few minutes" or "I need to be in my head for a second" which means they need to step away from their computer for a bit to handle issues IRL. Its common curtesy to NOT do this during large scenes or intense situations because it takes away from the experience but we all understand that sometimes life DOES get in the way. Just be mindful about its use.)</span ></li>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Praying To The Gods</span> - Sending off reports to admins or getting in contact with them about an issue.</span ></li>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>In City</span> - While in Server</span ></li>
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Tourist</span> Typically refers to a newer player and can be identified by not having eyebrows or holes in their shirts.</span ></li>                  
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Wizard</span> Refers to hackers. If you run into any, please contact admin ASAP.</span ></li>                  
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Headpop</span> Unexpected DC from the server.</span ></li>                  
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Muscles</span> The key binds you use to do something.</span ></li>                  
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Pockets</span> Refers to your inventory.</span ></li>                  <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Pockets</span> Refers to your inventory.</span ></li>                  
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Take A Nap</span> When you need to quit the game and wake back up.</span ></li>                  
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Fly Out/Go To Bed</span> When you are getting off for the day/night.</span ></li>                  
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Liberty City/Out Of City</span> Referring to IRL.</span ></li>                  
                                    <li style={{listStyleType:"circle",padding:"5px 0px"}}><span style={{color:"#ffffff"}}><span style={{background:"rgb(165, 7, 134)",padding:"5px",color:"#ffffff"}}>Paypal</span> The unique identifier assigned to each person. This can be found by either using your phone and at the top left there will be a number (E.G., #12) or by press and holding U and it will appear above your head as well as anyone else’s around you. This is required for some interactions in the city and can be useful when making tickets about another player. Keep in mind if you head pop your number will change when you wake back up. All paypals reset every wave.</span ></li>                  
                                </ul>
                            </li>
                            <li> 
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>***Player Reports and Bans***</h6>
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Any issues with other players should be reported through the proper channels, both IC and in the Discord ticket system. When you open a ticket, please respond to it with your issue and any evidence you have. When possible, get the players ID(s) by press and holding U.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Bans can also be appealed through a ban appeal form on the website, however there is no guarantee that any action will be taken. Each ban will be taken case by case and we are not required to tell you if it has been approved or denied.</span >
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>In-Game/ In-Character Information</h6>
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>This is meant to be a Role-Playing experience and the expectation is that you conduct yourself as though you are living in it.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>You must stay in character at all times. Newer players should try and use as much in city terminology as possible when asking questions (I.E., “what muscle do I use for...?”, “when is the wave/tsunami?”). The most common terms and phrases can be found above.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>When the server restart (the wave) is coming up, scenarios should NOT be started and any ongoing should continue naturally afterward. </span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>When dealing with scuff during a situation, please just roll with in the moment and RP it out. Your car poofs while doing a house robbery and the cops are there, “oh I guess a local stole it” and start running. Scuff happens sometimes, play out the scenario and make a ticket later. We will deal with everything as best we can, but you should not break character because of it. </span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Baiting people into situations that may cause them to say or do things that break the rules is not tolerated. Baiting people into situations they do not want to be in or repeatedly (force RPing) is not tolerated.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>These are serious violations and can result in a permanent ban.</span >
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>/OOC Usage</h6>
                                <span style={{color:"#ffffff"}}>This should be used sparingly; any issues should be reported through the support ticket system in the discord.</span >
                                <span style={{color:"#ffffff"}}>Appropriate use of /ooc can include “/ooc I headpopped while in the middle of a situation and woke up in the wrong place, can I get a TP.” </span >
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>Green Zones</h6>
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Green Zones refer to areas in the city that are a safe zone. They cannot be claimed as gang turf, you cannot start any conflicts, you cannot camp these areas waiting for someone to follow them until they are out of the Green Zone or take hostages from these zones. You also cannot use these zones as a shelter to escape from conflict. A warning will be issued for first offences, and any further incidents will result in a ban.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>The Green Zones in Gomorrah are as follows: Pillbox and other hospitals, MRPD and other police stations, The Richman Hotel (apartments), all player run businesses such as Cat Café, Tuner Shop, White Widow and the Vespucci cinema. </span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>The ONLY exception to this rule is if it is an ongoing situation. (I.E., your buddy has been arrested and taken to Pillbox, you are allowed to try and get them out anyway you can whilst following all other rules.)</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>People who are using menus such as the clothing store or the catalogue at PDM should also considered in a Green Zone until they have left and can interact appropriately.</span >
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>Metagaming/ Power Gaming (Fail RP)</h6>
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Metagaming is defined as purposefully using or relaying information that is found out OOC (through twitch chats, discord messages, Voice Calls with others, things you learn as one of your other characters) to shape any form of your RP.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Power Gaming is defined as using any means NOT in-game to gain an upper hand on situations (I.E., Talking/Calling Out or trying to Organize locations while downed. If you are down, treat it as so) or not attempting to fit into the city in a sensible way (I.E., speed running to make money instead of finding out how mechanics work properly through natural RP.) </span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Fail RP is defined as when a person fails to roleplay properly whether that be by breaking character, talking OOC, intentionally disrupting someone else roleplay, or not initiating scenes properly.</span >
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>Not Valuing Life, Random Death Match (RDM) And Vehicle Death Match (VDM)</h6>
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Not fearing for your life when you are threatened with weapons or severe harm from any other means is considered NVL and Fail RP. This will result in a warning for first time offence and bans from then on. </span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Having sudden and unprompted shootouts or fights, antagonizing gangs/police, or anyone else for no justifiable IC reason is considered RDM. This will result in a warning for first time offence and bans from then on.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Attacking another player/group using any vehicle with the intention to kill/knockout without valid reasoning or initiation (excessive speeds of 100+/ ramming) is considered VDM. This will result in a warning for first time offence and bans from then on.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Players should not be interfering or inserting themselves into active scenarios/shootouts, etc., between two groups (I.E., 2 gangs at war, 1 group and the police) that they were not initially involved in, no matter the relation to a group or person(s) involved. This is classified as third partying and is not tolerated. This may result in an instant ban.</span >                
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>All players reserve the right to walk away from any extreme RP situation they do not want to be a part of. This includes (but is not limited to); suicidal/self-harm RP, extreme medical conditions such as cancer or deadly diseases, complications that might result in death. </span >                
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>**EMS at any point have the right to refuse medical attention for any reason if a player makes them uncomfortable or broach a topic that they cannot handle or speak on for the sake of the player**</span >          
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>New Life and Multiple Character Rule</h6>
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>If you get downed, you MUST try and contact medical help of some sort whilst incapacitated. You can use /311 or /311a for this (I.E., /311a someone just got attacked by a murder kitty, please help). DO NOT respawn if you have been advised police or EMS are on the way. This will result in a warning for first time offence and bans from then on.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>If you get downed and need to respawn at the hospital your character forgets the immediate events leading up to being downed. A good rule of thumb is the situation you were in or about 30 minutes. Remembering and acting on this will result in a warning for first time offence and bans from then on.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>If a player or group of players gets in a shootout/active scene with another group and anyone (police or crim) gets picked up (revived through Pillbox, PD Airlift, Grandmas/ Grandpas or otherwise) those players are NOT allowed to re-engage in the same situation. This will result in a warning for first time offence and bans from then on.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Having multiple characters is allowed, and even encouraged, however, they are not allowed in the same gang or group. Transferring goods, information, money, or weapons between your characters is forbidden. (This includes, but is not limited to, being on your cop and telling the gang of your crim information about another player or heist, identifying people based on meeting them with a different character, allowing someone in your crims gang to rob your cop for PD equipment.) Anyone caught doing this will be immediately banned for a minimum of 7 days. </span >                
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>Criminal Activities</h6>
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Gomorrah has a strict 4-man rule. There cannot be more than 4 people involved in any crime at any time. This includes having someone help you get out by picking you up. You must be completely clear before getting anyone else involved. For example, you could have 2 people robbing the jewelry store, have one person waiting with a car somewhere else for a swap, in this instance one other person could be called in while being chased if needed. If you had 3 people in the store and 1 waiting, you could not involve anyone else until you are clear from the situation, even if the rest of your crew is still being chased. However, at this point while you might be considered out, they could not have someone not originally involved help them.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Relaying any information (through phone, lookouts, radio, etc.) COUNTS you as part of the group.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Any and all PvP interactions are also included in the 4-man rule. However, keep in mind that if you are in a 4-man group antagonizing another larger group, such as a gang on their home turf, all present are greenlit to participate.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Home turf advantage MUST start on your turf, NOT be brought to it! </span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>When there is a gang war ongoing, the 4-man rule still applies when your group is pushing another gang's turf. Additionally, if a gang is pushing another gang's turf all 4 members of the group MUST be officially apart of that gang. If you are hanging out with a gang that gets pushed while you are present, you are greenlit to participate however you feel as you are acting in self-defense at that point.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>When planning a heist or robbery you should have a hostage and more than one person on your team, otherwise the police have every right to decide to breach and shoot you down.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>You are not allowed to resupply during heist's once the police have arrived on scene (The group should be prepared once the heist starts to follow through to the end with the items they initially brought.) If scuff happens, roll with it. These things will happen from time to time, so do your best to just go with it and make a ticket afterwards. Include any clips you have of the scuff happen and include a screenshot of your F8 menu if there are any errors displayed. This will help us identify the issue and rectify it. In rare circumstances you may have any items returned to you that you lost due to scuff.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Interfering or breaching a heist that is already ongoing is not allowed. This is classified as third partying and is not tolerated. This may result in an instant ban.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Prison breaks are allowed; however, they should be rare and only done in extreme situations (I.E., you get someone arrested over a simple store robbery and they already have a warrant). The 4-man rule still applies and includes anyone being broken out. If you are unsure if your situation is extreme enough, reach out to an admin or make a ticket to ask. We will be happy to help guide you in whether we think it would be acceptable or not.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>If you are trying to help get someone out of being arrested, the same 4-man rule as a prison break applies, wherein the person(s) being saved are included in the group. This is the only situation where it is acceptable to break the green zone rule of Pillbox, as it is considered an ongoing scenario. Once the person(s) have been transported to MRPD and entered the underground garage, any violent attempts must end. If you can come up with a way to convince police to give up custody at this point, go for it. It is extremely unlikely that it will succeed but we encourage all attempts at good RP. </span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>Anyone that is in police custody, in the cells of MRPD, etc. and head pops or when the wave hits must wake back up and turn themselves in. If you are unable to wake back up reach out to someone involved and let them know. You will be required to turn yourself in the next time you wake up but a heads up is a good thing so the other side can continue to RP instead of waiting for you.</span >
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>Identity Misuse IC</h6>
                                <span style={{color:"#ffffff"}}>This is meant specifically to address the misuse of another player's character (also a form of identity theft/impersonation) which will not be tolerated. Other players MUST be contacted and confirmed that it is okay with them if you intend to use ANYTHING involving a character, they possess including but not limited to:
                                    <ul style={{marginLeft:"20px"}}>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>Faking/dressing up as said character for scenes.</span ></li>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>False Flagging (wearing another gangs colors or another MC's Kuttes) to start conflict or shift blame.</span ></li>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>False Calls/Messages to other players assuming the identity of another to create conflict, use fear tactics or gain information.</span ></li>
                                    </ul>
                                </span >  
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>If you believe someone is faking another player's character or any of the above, PLEASE make a ticket so that this can be handled. This will result in a warning for first time offence and bans from then on.</span >              
                            </li>
                            <li>
                                <h6 className="subtitle" style={{color:"rgb(165, 7, 134)",padding:"20px 0px 10px",animation:"none",fontWeight:"bold"}}>Harassment IC and OCC</h6>
                                <span style={{color:"#ffffff"}}>Harassment of ANY KIND will NOT be tolerated within Gomorrah. This primarily is a safe space for ALL our players. Things that might fall under this category are:
                                    <ul style={{marginLeft:"20px"}}>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>Attempting to force another player(s) into RP scenarios they are not comfortable with.</span ></li>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>Forcing other players to interact when/if they have stated they did not want to or were not interested.</span ></li>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>Forcing contact with a person(s) through texting or spamming phone calls IC or spamming DMs in discord (or any other platform) if it is over anything that may have upset someone or frustrated them.</span ></li>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>Going onto any other social media platforms IRL to berate or make comments to or about someone due to something that happened in city.</span ></li>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>Actively going out of your way to make another player's life difficult or a living hell through both RP and OOC means.</span ></li>
                                        <li style={{listStyleType:"circle"}}><span style={{color:"#ffffff"}}>Contacting admins directly to complain about a person, group, game mechanic, or anything else constantly instead of going through the proper channels.</span ></li>                
                                    </ul>
                                </span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>At the end of the day, we all come here to enjoy ourselves and be someone else to escape our everyday struggles. No one deserves to feel uncomfortable.</span >
                                <span style={{padding:"10px 0px",color:"#ffffff"}}>If you or anyone in the server suspects or IS being harassed, PLEASE make a ticket and it will be handled accordingly. If it is a more personal matter and you do not want the whole team to see the ticket, reach out to one of us and ask if we are ok with helping privately. We want everyone to feel comfortable both in the server and OOC and will do whatever we can to help.</span >                
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Outlet />
        </div>        
    );
};

export default Rules;