import React from 'react';

const SectionFooter = () => {
    return (    
        <footer className="footer-section">
            <div className="container">
                <div className="footer-pic" style={{position: "absolute",bottom: "0",left: "-10%",width: "120%",zIndex:"0"}}>
                    <img src="./imgs/footer_bg.png" width="100%" height="auto" alt=""/>
                </div>                
                <a href="#" className="footer-logo">
                    <img src="./img/mstile-144x144.png" alt=""/>
                </a>
                <ul className="main-menu footer-menu">
                    <li className="list-head" style={{textAlign:"center",padding:"20px 0px"}}>
                        <h6 className="font-weight-bold" style={{letterSpacing:"4px"}}>Patreon</h6>
                    </li>
                    <li className="list-body" style={{textAlign:"left"}}>
                        <p style={{display:"block",margin:"auto",color:"#ffffff"}}>
                        Like what you see?<br/>
                        Why not support the cause and check out our Patreon!
                        Exclusive rewards for supports like behind the scenes looks at art concepts for clothing/ gang sprays, Custom Digital Postcards exclusive to Gomorrah RP and more!<br/><br/>
                            <a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"261px",margin:"25px auto", paddingBottom:"10px",display:"block",background:"rgb(79, 54, 216)",borderColor:"rgb(79, 54, 216)"}} href="https://www.patreon.com/GomorrahRPServer"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>Check it out!</span></a>
                        </p>
                    </li>
                </ul>
                <div className="footer-social d-flex justify-content-center">
                <a href="https://twitter.com/GomorrahRp" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-twitter" style={{color:"#ffffff",fontSize:"25px",textShadow:"rgb(57, 21, 117) 2px 2px 3px"}}></i></a>
                    <a data-testid="linkElement" href="https://discord.gg/pNAewFRcdC" target="_blank" rel="noreferrer noopener" className="oRtuWN">                                    
                        <i className="fa-brands fa-discord" style={{color:"#ffffff",fontSize:"25px",textShadow:"rgb(57, 21, 117) 2px 2px 3px"}}></i>
                                {/* <img alt="Discord     " fetchpriority="high" src="https://static.wixstatic.com/media/11062b_5786213245914dde873f6b286a16854c~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_5786213245914dde873f6b286a16854c~mv2.png" style={{width:"30px",height:"30px"}}/>                                     */}
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100091572442145" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-facebook" style={{color:"#ffffff",fontSize:"25px",textShadow:"rgb(57, 21, 117) 2px 2px 3px"}}></i></a>
                </div>
                <div className="copyright">Copyright <script>document.write(new Date().getFullYear())</script> &copy;</div>
            </div>
        </footer>            
    );
};

export default SectionFooter;