const LifeChoices = {
    items : [
        {
            title:"Civilians",
            img:"civilians.png",
            Text:() => {
                return(
                    <div>
                        <div><h2></h2></div>
                        <div>
                            <p></p>
                            <p>As an Civilian you can: 
                                <ul>
                                    <li></li>
                                </ul>
                            </p>
                            <p>Are you ready for this?</p>
                        </div>
                    </div>
                )
            }
        },
        {
            title:"EMS",
            img:"EMS.png",
            Text:() => {
                return(
                    <div>
                        <div><h2>Save the city!</h2></div>
                        <div>
                            <p>As an EMS your task is to be ready to help people in need! Working closely with PD your life will never have been more event full!</p>
                            <p>Join our colorful staff and be part of the action.</p>
                            <p>As an EMS you can: 
                                <ul>
                                    <li>Drive the ambulance through the city</li>
                                    <li>Give ice pack to injured people to make them feel better</li>
                                    <li>Be part of medical procedures</li>
                                    <li>Be a firefighter (yes drive the fire truck)</li>
                                    <li>Do undrewather rescues</li>
                                    <li>And many more..</li>
                                </ul>
                            </p>
                            <p>Are you ready for this?</p>
                        </div>
                    </div>
                )
            }
        },
        {
            title:"Cops",
            img:"cops2.png",
            Text:() => {
                return(
                    <div>
                        <div><h2></h2></div>
                        <div>
                            <p></p>
                            <p>As an Civilian you can: 
                                <ul>
                                    <li></li>
                                </ul>
                            </p>
                            <p>Are you ready for this?</p>
                        </div>
                    </div>
                )
            }
        },
        {
            title:"Criminals",
            img:"crims.png",
            Text:() => {
                return(
                    <div>
                        <div><h2>Crime for life!</h2></div>
                        <div>
                            <p></p>
                            <p>As an Civilian you can: 
                                <ul>
                                    <li></li>
                                </ul>
                            </p>
                            <p>Are you ready for this?</p>
                        </div>
                    </div>
                )
            }
        }
    ]    
};

export default LifeChoices;