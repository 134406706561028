const team = {
    items : [       
        {
            name:'Lu Diabla/Midnight Castle/ Bepis The Hen', 
            role:'Admin & Developer',
            images:[
                {name:"Lu Diabla",src:"Lu Diabla.png"},
                {name:"Midnight Castle",src:"Midnight Castle.png"},
                {name:"Bepis The Hen",src:"Bepis The Hen.png"}
            ],
            links:[]
        },
        {
            name:'Ron Wild/ Jon Mild', 
            role:'Admin & Developer',
            images:[
                {name:"Ron Wild",src:"Ron Mild.png"},
                {name:"Jon Mild",src:"Jon Mild.png"}
            ],
            links:[]
        },       
        {
            name:'Goose Weaselton/ Tom Bombadil', 
            role:'Server Owner & Admin & Developer',
            images:[
                {name:"Goose Weaselton",src:"Goose Weaselton.png"},
                {name:"Tom Bombadil",src:"Tom Bombadil.png"}
            ],
            links:[]
        },
        {
            name:'Rose McCloud/ June Collins/ Amelia Goode', 
            role:'Web Developer & Admin & Developer & Social Management',
            images:[
                {name:"June Collins",src:"June Collins.png"},
                {name:"Julia Collins",src:"Julia Collins.png"}
            ],
            links:[]
        },
        // {
        //     name:'Ivan Blackheart / Lux Valentine', 
        //     role:'Developer & 3d Modeler',
        //     images:[
        //         {name:"Ivan Blackheart",src:"Ivan Blackheart.png"},
        //         {name:"Lux Valentine",src:"Lux Valentine.png"}
        //     ],
        //     links:[]
        // },
        // {
        //     name:'Kasper Storsve/ Sam Storsve', 
        //     role:'Developer',
        //     images:[
        //         {name:"Kasper Storsve",src:"Kasper Storsve.png"},
        //         {name:"Sam Storsve",src:"Sam Storsve.png"}
        //     ],
        //     links:[]
        // }
    ]    
};
export default team;