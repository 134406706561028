import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Evenments from '../data/Events'
require('react-big-calendar/lib/css/react-big-calendar.css');

const localizer = momentLocalizer(moment)

const MyCalendar = (props) => {
    return (
  <div>
    <Calendar
      localizer={localizer}
      events={Evenments.items}
      startAccessor="start"
      endAccessor="end"
      style={{ height: "500px",width:"500px",fontFamily:"Arial" }}
    />
  </div>
);
};

export default MyCalendar;

