import React from "react";
import { useState,useEffect  } from 'react';
import {Nav, NavTop, NavLink, Bars, NavMenu, NavBtn, NavBtnLink} from './NavbarElements';
import { useLocation } from 'react-router-dom'



const NavBar = () => {   
    const [showNavbar, setShowNavbar] = useState(false)
    const LocationPath = useLocation().pathname;

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }
    function Getlocation() {
        const location = useLocation();  
        
        return location.pathname.replace('/', '');
    }
   
    return (
        <>
        <header className={"header-section " + Getlocation()}>
            <div className="header-warp">
                <NavTop>
                    <NavMenu className="top-menu row">
                        <NavLink to='/' activestyle="true" className="col-md-2">Home</NavLink>
                        <NavLink to='/about' activestyle="true" className="col-md-2">About</NavLink>
                        <NavLink to='/rules' activestyle="true" className="col-md-2">Rules</NavLink>
                        <NavLink to='/team' activestyle="true" className="col-md-2">Team</NavLink>                
                    </NavMenu>
                </NavTop>

                <div className="header-social d-flex justify-content-end" >
                    <NavBtn>
                        <NavBtnLink to='https://cfx.re/join/9g7p3k'  activestyle="true" className="btn_menu login">
                            Login
                        </NavBtnLink>
                    </NavBtn> 
                    {/* <p>Follow us:</p>                    
                    <a href="https://twitter.com/GomorrahRp" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-twitter" style={{color:"#fff",fontSize:"25px",textShadow:"rgb(57, 21, 117) 2px 2px 3px"}}></i></a>
                    <a data-testid="linkElement" href="https://discord.gg/pNAewFRcdC" target="_blank" rel="noreferrer noopener" className="oRtuWN">                                    
                        <i className="fa-brands fa-discord" style={{color:"#fff",fontSize:"25px",textShadow:"rgb(57, 21, 117) 2px 2px 3px"}}></i>                              
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100091572442145" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-facebook" style={{color:"#fff",fontSize:"25px",textShadow:"rgb(57, 21, 117) 2px 2px 3px"}}></i></a> */}
                </div>
                <div className="header-bar-warp">
                    
                    <NavLink to='/' activestyle="true" className="site-logo">
                        <img src="./imgs/GM.gif" width="75" height="auto" style={{minWidth:"60px",width:"60px",height:"60px",margin:"10px auto 15px 0px",borderRadius:"99999px"}} alt=""/>
                    </NavLink>
                    
                    <Nav className="top-nav-area w-100" data-spy="affix" data-offset-top="20">
                        <div className="menu-icon" onClick={handleShowNavbar}>
                            <Bars/>
                        </div>
                        <div className={`${showNavbar && 'active'}`}>
                            <NavMenu className="menu-mobile">
                                <NavLink to='/' activestyle="true" className="col-md-2" onClick={handleShowNavbar}>Home</NavLink>
                                <NavLink to='/about' activestyle="true" className="col-md-2" onClick={handleShowNavbar}>About</NavLink>
                                <NavLink to='/rules' activestyle="true" className="col-md-2" onClick={handleShowNavbar}>Rules</NavLink>
                                <NavLink to='/team' activestyle="true" className="col-md-2" onClick={handleShowNavbar}>Team</NavLink>  
                                <NavLink to='/documents' activestyle="true" onClick={handleShowNavbar}>Document</NavLink>
                                <NavLink to='/gallery' activestyle="true" onClick={handleShowNavbar}>Gallery</NavLink>             
                                <NavLink to='/contact' activestyle="true" onClick={handleShowNavbar}>Contact</NavLink> 
                                {/* <NavBtnLink to='https://cfx.re/join/634ep8'  activestyle="true" className="btn_menu" onClick={handleShowNavbar} target="_blank" aria-disabled>
                                    Login
                                </NavBtnLink>                                                        */}
                            </NavMenu>
                        </div>
                        <div>
                            <NavMenu className="main-menu primary-menu">
                                <NavLink to='/' activestyle="true"><i className="ti-home" title="Home"></i></NavLink>
                                <NavLink to='/documents' activestyle="true"><i class="ti-write" title="Document"></i></NavLink>
                                <NavLink to='/gallery' activestyle="true"><i className="ti-camera" title="Gallery"></i></NavLink>             
                                <NavLink to='/contact' activestyle="true"><i className="ti-email" title="Contact"></i></NavLink>                            
                            </NavMenu>   
                            {/* <NavBtn  className="user-panel">
                                <NavBtnLink to='https://cfx.re/join/9g7p3k'  activestyle="true" className="btn_menu">
                                    Login
                                </NavBtnLink>
                            </NavBtn>   */}
                            <div className="Nav-bar-social">
                                <a data-testid="linkElement" href="https://discord.gg/pNAewFRcdC" target="_blank" rel="noreferrer noopener" className="oRtuWN">   <i className="fa-brands fa-discord"></i></a>
                                <a href="https://twitter.com/GomorrahRp" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-twitter"></i></a>
                                <a href="https://www.facebook.com/profile.php?id=100091572442145" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-facebook"></i></a>
                                <i className="fa-brands fa-instagram"></i>
                                <a href="https://www.tiktok.com/@gomorrahrp?is_from_webapp=1&sender_device=pc" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-tiktok"></i></a>
                                <a href="https://www.patreon.com/GomorrahRPServer" target="_blank" rel="noreferrer noopener"><i className="fa-brands fa-patreon"></i></a>                                
                            </div>                            
                        </div>                       
                    </Nav>
                </div>
            </div>
	    </header>  
        <section className={"header hero-section overflow-hidden " + Getlocation()}>  
            <div className={`${showNavbar && 'active'}`}>          
                <div className="hero-item set-bg d-flex align-items-center justify-content-center text-center">
                    <div className="container" style={{marginTop:"60px"}}>
                        <h6 className="subtitle">Choose your destiny</h6>
                        <h1 className="title">Gomorrah RP</h1>                        
                        <div className="buttons text-center" style={{padding:'20px 0px'}}>
                            <NavBtnLink to='/about'  activestyle="true" className="btn btn-primary rounded w-lg btn-lg my-1 site-btn">About Gomorrah <img src="img/icons/double-arrow.png" alt="#"/></NavBtnLink>
                            <NavBtnLink to='/contact' activestyle="true" className="btn btn-primary rounded w-lg btn-lg my-1 site-btn">Contact Us <img src="img/icons/double-arrow.png" alt="#"/></NavBtnLink>
                        </div>  
                        
                    </div>
                </div>   
            </div>                        
        </section>       
        </>
    );
};

export default NavBar;