import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import LifeChoices from "../data/LifeChoices";

const SectionLifeChoices = () => {
    return (
        <section className="intro-video-section set-bg d-flex align-items-center " data-setbg="./imgs/20230315010934_1.jpg" style={{background:"url(./imgs/20230315010934_1.jpg) no-repeat center center fixed",backgroundSize:"100%"}}>            
            
                <div style={{width:"100%"}}>
                    <Carousel controls={true} fade={true} pause={'hover'} interval={10000} slide={true} className='lifeChoices'>
                        {LifeChoices.items.map((item, index) => (                                                   
                            <Carousel.Item  Key={item.title}>
                            <div style={{position:"relative",paddingTop:"20px",textAlign:"center"}}>
                                <img src={"../imgs/" + item.img} style={{width:"50%",height:"auto"}}/>
                                <span className="title" style={{position:"absolute", bottom:"0",right:"10%",color:"rgb(255 255 255)",fontSize:"26px",textShadow:"rgba(174 225 122, 0.7) 2px 2px 3px"}}>{item.title}</span>
                            </div>   
                            </Carousel.Item>                                    
                        ))}                                                
                    </Carousel>
                </div>
          
        </section>        
    );
};

export default SectionLifeChoices;