import React from 'react';
import {Outlet} from 'react-router-dom';
import teamData from '../components/data/Team';
import Carousel from 'react-bootstrap/Carousel';

const Team = () => {    
    return (
        <div> 
            <section className="intro-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> 
                            <div style={{padding:"30px 0px"}}>
                                <h1 className="title" style={{display:"block",color:"#ffffff",fontSize:"50px"}}>The Team</h1>
                            </div>
                            <div>
                                <h6 className="section-subtitle" style={{fontFamily:"Roboto, sans-serif", fontWeight:"700"}}>Meet With</h6>
                                <h6 className="section-title mb-5">Our Angels</h6>
                            </div>
                        </div>
                    </div>                    
                </div>                                
            </section>
            <section className="blog-section spad">
                <div className="container">              
                    <div className="row">
                        <>
                            {
                            teamData.items.map((item, index) => (
                                <div Key={item.name} className="col-sm-8 col-md-6">
                                    <div className="card text-center mb-4">
                                        <div className="frame" style={{padding:"15px 0px"}}>
                                        <Carousel controls={false} fade={true} pause={'hover'} interval={5000} slide={true}>
                                            {item.images.map((image, index) => (                                                   
                                                <Carousel.Item  Key={item.name}>
                                                <div style={{position:"relative"}}>
                                                    <img src={"../imgs/team/" + image.src} style={{width:"90%",height:"auto",border:"4px solid #AB15D1"}}/>
                                                    <span className="title" style={{position:"absolute", bottom:"0",right:"10%",color:"rgb(255 255 255)",fontSize:"20px",textShadow:"rgba(174 225 122, 0.7) 2px 2px 3px"}}>{image.name}</span>
                                                </div>   
                                                </Carousel.Item>                                    
                                            ))}                                                
                                        </Carousel>
                                        </div>                                    
                                        <div className="card-body">
                                            <h6 className="small text-primary font-weight-bold" style={{color:"rgb(242, 148, 35)", fontFamily:"Arial", fontWeight:"bold"}}>{item.role}</h6>
                                            <h5 className="card-title" style={{color:"#AB15D1",fontFamily:"Arial", fontWeight:"bold",height:"50px"}}>{item.name}</h5>
                                            <p>...</p>
                                            <div className="socials" style={{display:"none"}}>
                                                <a href="javascript:void(0)"><i className="ti-facebook"></i> </a>
                                                <a href="javascript:void(0)"><i className="ti-github"></i></a>
                                                <a href="javascript:void(0)"><i className="ti-dropbox"></i></a>
                                                <a href="javascript:void(0)"><i className="ti-twitter"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>  
                    </div>
                </div>
            </section>
            <Outlet />
        </div>        
    );
};

export default Team;