import React from 'react';
import ImageGallery from './ImageGallery';

const ChildImagesItem = ({ images }) => {
  return (    
        <ImageGallery images={images} />
  );
};

export default ChildImagesItem;
