import React, { useState } from "react";

const ImageGallery = ({ images }) => {
  const [currentImageSrc, setCurrentImageIndex] = useState(null);

  const handleImageClick = (src) => {
    setCurrentImageIndex(src);
  };

  const handleModalClose = () => {
    setCurrentImageIndex(null);
  };

  const generateThumbnails = () => {
    return images.map((image, index) => (
      <img
        key={index}
        src={image}
        alt="Gallery thumbnail"
        onClick={() => handleImageClick(image)}
        style={{ margin: "5px", cursor: "pointer" }}
      />
    ));
  };

  const generateModal = () => {
    if (currentImageSrc !== null) {
      return (
        <div className="modal" onClick={handleModalClose}> 
          <img src={currentImageSrc} alt="Gallery" className="modal-content" />
        </div>
      );
    }
  };

  return (
    <div>
      <div style={{  }}>{generateThumbnails()}</div>
      {generateModal()}
    </div>
  );
};

export default ImageGallery;
