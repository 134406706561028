const ChangeLogs = {
    items : [
        {
            title:"Changelog Updates - 2024",
            date:"2024",
            link:"/changelog",
            preview:"Something new is comming to town",
            info:() => {
                return(
                    <div>
                        <div>
                            <h6>Promo:</h6>
                        </div>
                        <div style={{textAlign:"left",padding:"20px 0px"}}>
                            <p>
                                <span style={{color:"#ffffff"}}>1.5 is coming soon be ready:</span>
                            </p>
                        </div>   
                        <div>
                          <iframe width="560" height="315" src="https://www.youtube.com/embed/JHNiKnOBwk4?si=vVgtUPRZ5JPrmiCm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                     </div>
                    </div>
                )
            },
        },
        // {
        //     title:"Changelog Updates - May 2023",
        //     date:"May, 2023",
        //     link:"/changelog",
        //     preview:"The following updates have been made in the month of May to Gomorrah. New Boosting script added (if anyones boosting rep is scuffed out please make a ticket) ...",
        //     info:() => {
        //         return(
        //             <div>
        //                 <div>
        //                     <h6>Change Log Updates:</h6>
        //                 </div>
        //                 <div style={{textAlign:"left",padding:"20px 0px"}}>
        //                     <p>
        //                         <span style={{color:"#ffffff"}}>The following updates have been made in the month of May to Gomorrah:</span>
        //                     </p>
        //                 </div>   
        //                 <div  style={{textAlign:"left"}}>                                               
        //                     <p>
        //                         <span style={{fontWeight:"700", padding:"10px 0px",display:"block",color:"#ffffff"}}>---Patch Update---</span>                                
        //                         <ul style={{listStyle:"disc outside",marginLeft:"15px"}}>                                    
        //                             <li><span style={{color:"#ffffff"}}>May 10, </span>
        //                                 <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
        //                                     <li>New Boosting script added (if anyones boosting rep is scuffed out please make a ticket)</li>
        //                                     <li>Weed Buffs (Male Plants got a buff on the amount of seeds you get and cornering time has been improved)</li>
        //                                     <li>2 New minigames are added</li>
        //                                     <li>New Heists are coming soon!!!!!</li>
        //                                 </ul>
        //                             </li>                                                                        
        //                         </ul>
        //                     </p>
        //                 </div>
        //             </div>
        //         )
        //     }
        // },
    //     {
    //         title:"Changelog Updates - April 2023",
    //         date:"Apr, 2023",
    //         link:"/changelog",
    //         preview:"The following updates have been made in the month of April to Gomorrah. Racing update! All criminal heists set to no cops needed ...",
    //         info:() => {
    //             return(
    //                 <div>
    //                     <div>
    //                         <h6>Change Log Updates:</h6>
    //                     </div>
    //                     <div style={{textAlign:"left",padding:"20px 0px"}}>
    //                         <p>
    //                             <span style={{color:"#ffffff"}}>The following updates have been made in the month of April to Gomorrah:</span>
    //                         </p>
    //                     </div>   
    //                     <div  style={{textAlign:"left"}}>                                               
    //                         <p>
    //                             <span style={{fontWeight:"700", padding:"10px 0px",display:"block",color:"#ffffff"}}>---Patch Update---</span>                                
    //                             <ul style={{listStyle:"disc outside",marginLeft:"15px"}}>
    //                                 <li><span style={{color:"#ffffff"}}>April 5, </span>
    //                                     <ul style={{listStyle:"disc outside"}}>
    //                                         <li>
    //                                             <div>
    //                                                 <p>
    //                                                     <span style={{fontWeight:"700", padding:"10px 0px",display:"block",fontWeight:"700",color:"#ffffff"}}>Racing Update</span>
    //                                                 </p>
    //                                             </div>
    //                                             <div>
    //                                                 <iframe width="560" height="315" src="https://www.youtube.com/embed/rxrEik-CQGQ" title="YouTube video player" style={{maxWidth:"90%"}} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    //                                             </div>
    //                                         </li>                                            
    //                                     </ul>
    //                                 </li>  
    //                                 <li><span style={{color:"#ffffff"}}>April 14, </span>
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>All criminal heists set to no cops needed</li>
    //                                         <li>Hotel removed</li>
    //                                         <li>New storage system added</li>
    //                                     </ul>
    //                                 </li> 
    //                                 <li><span style={{color:"#ffffff"}}>April 17, </span>
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>* Fixed /escort <br/>Escorting someone in a 2 seater is now possible </li>
    //                                     </ul>
    //                                 </li> 
    //                                 <li><span style={{color:"#ffffff"}}>April 21, </span>
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>New Items are sellable at the illegal sales local (laptop & tablet)</li>
    //                                         <li>Cornering prices updated</li>
    //                                         <li>Local shop added to Digital Den</li>
    //                                         <li>New MRPD and Ranger Station</li>
    //                                         <li>ClockIn/Out for the state has been changed to target and The ability to grab new documents to give to business owners</li>
    //                                         <li>MRPD Gas pumps added</li>
    //                                     </ul>
    //                                 </li> 
    //                                 <li><span style={{color:"#ffffff"}}>April 28, </span>
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>New Prison </li>
    //                                         <li>Prison Gangs have been changed (G59, Angels, and Devils) Next time you are in prison find the prison gang local to join a prison gang</li>
    //                                         <li>When in prison and downed you will go to the prison hospital instead of Pillbox</li>
    //                                         <li>A Bunch of quality of life changes made to prison</li>
    //                                     </ul>
    //                                 </li>                                     
    //                             </ul>
    //                         </p>
    //                     </div>
    //                 </div>
    //             )
    //         }
    //     },
    //     {
    //         title:"Changelog Updates - March 2023",
    //         date:"Mar, 2023",
    //         link:"/changelog",
    //         preview:"The following updates have been made in the month of March to Gomorrah. The F1 group menu has been removed, All the jobs related to that group menu are also removed, New jobs have been added Find out in RP, Fishing Tournament is also Fixed  ...",
    //         info:() => {
    //             return (
    //                 <div>
    //                     <div>
    //                         <h6>Change Log Updates:</h6>
    //                     </div>
    //                     <div style={{textAlign:"left",padding:"20px 0px"}}>
    //                         <p>
    //                             <span>The following updates have been made in the month of March to Gomorrah:</span>
    //                         </p>
    //                     </div>                        
    //                     <div  style={{textAlign:"left"}}>                                               
    //                         <p>
    //                             <span style={{fontWeight:"700", padding:"10px 0px",display:"block"}}>---Patch Update---</span>                                
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>March 2, 
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>The F1 group menu has been removed</li>
    //                                         <li>All the jobs related to that group menu are also removed</li>
    //                                         <li>New jobs have been added Find out in RP</li>
    //                                         <li>Fishing Tournament is also Fixed </li>
    //                                         <li>Weed Plants should no longer poof (this is still being looked at)</li>
    //                                         <li>Gas prices are adjusted and might still get changed</li>
    //                                         <li>House Robbery Local is now visable to everyone</li>
    //                                         <li>More bait was given to the fishing sales guy</li>
    //                                     </ul>
    //                                 </li>    
    //                                 <li>March 3, 
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>Female Characters now will have full health instead of 75%</li>
    //                                         <li>The camera view has been reverted</li>
    //                                         <li>You will no longer run funny when you shoot a gun</li>
    //                                         <li>Hunting has been removed (This means some hunting items might poof from your pockets)</li>
    //                                         <li>A new job has been added to replace hunting Find out in RP</li>
    //                                         <li>Moore Club (VU) construction is completed</li>
    //                                         <li>Inventory Ui has been changed</li>
    //                                         <li>Walking styles will now stick try it out with /walks</li>      
    //                                         <li>Seatbelt sound is now synced to the seatbelt update last week</li>                          
    //                                         <li>Payments can now go through the phone and cash registers at businesses (This is still being looked at)</li>
    //                                         <li>/racing command disabled new features coming soon!!</li>
    //                                         <li>Apartments and or some form of storage for you guys is coming soon!!!</li>
    //                                     </ul>
    //                                 </li>    
    //                                 <li>March 6, 
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>311s,911s,911as all work now</li>
    //                                     </ul>
    //                                 </li>
    //                                 <li>March 7, 
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>Banking is being adjusted (this is still very new and will be worked out quick)</li>
    //                                         <li>You will no longer be able to give items to others using your pockets</li>
    //                                         <li>House Robbery has been adjusted to help with losing jobs in the middle of runs</li>
    //                                         <li>House Robbery Cancel option is disabled (It is still visable and will tell you that you canceled the job but it will not work)</li>
    //                                         <li>Fishing Tournament is fixed</li>
    //                                         <li>Oxy Disabled a replacement is on the way very soon</li>
    //                                         <li>Motel Rooms (Find out in RP)</li>
    //                                         <li>/oocl is coming very soon</li>
    //                                         <li>Weed is still ongoing (Plants poof)</li>
    //                                         <li>Charging customers via the phone now works with Paypal instead of CID. Also includes commission automatically for staff</li>
    //                                         <li>paying employees via the employment app now works</li>
    //                                     </ul>
    //                                 </li>  
    //                                 <li>March 8,
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>Oxy Enabled some changes have been made</li>
    //                                         <li>charging people via the phone broke again...</li>
    //                                     </ul>
    //                                 </li>
    //                                 <li>March 11, 
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>Oxy Location scuff fixed (picking up the packages local still being looked at)</li>
    //                                         <li>Selling drugs is fixed will not kick people anymore as well</li>
    //                                         <li>Hunting added</li>
    //                                         <li>New crim job added</li>
    //                                         <li>Lockpick animations will now loop</li>
    //                                         <li>Store Robbery registers deleted</li>
    //                                         <li>Garage Updated</li>
    //                                         <li>Digital Den got new items</li>
    //                                         <li>Towing Job added</li>
    //                                         <li>Missing Mats added to recycling</li>
    //                                     </ul>
    //                                 </li>  
    //                                 <li>March 15,
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>Phone - Employment: no longer clocks you on as it has been causing scuff, only switches your job now</li>  
    //                                         <li>House Robberies: - Dispatch fixed + updated</li>                                          
    //                                         <li>Hunting: - Dispatch fixed</li>                                          
    //                                         <li>Hunting: - Bigfoot now retrievable</li>                                          
    //                                     </ul>
    //                                 </li>
    //                                 <li>March 18,
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>New Weed System</li>     
    //                                         <li>Garage Updated</li>                                       
    //                                         <li>Weapon Damage has been adjusted</li> 
    //                                         <li>New Corner Selling (Do not corner next to locals that give you jobs like the fishing guy or the oxy guy)</li> 
    //                                     </ul>
    //                                 </li>
    //                                 <li>March 25,
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>Weedruns removed </li>     
    //                                         <li>Hunger and Thirst values have been buffed</li>                                       
    //                                         <li>New crim job added</li> 
    //                                         <li>Gun damage when shooting has been reduced (your gun will take less damage when you shoot it)</li> 
    //                                         <li>Locals move out of cops way to the side of the road when they have sirens on instead of just swerving and stopping</li>
    //                                     </ul>
    //                                 </li>
    //                                 <li>March 30, 
    //                                     <ul style={{listStyle:"disc outside",marginLeft:"30px"}}>
    //                                         <li>Several quality of life updates made to cornering</li>
    //                                         <li>Digital Den location updated</li>
    //                                         <li>Burgershot updated</li>
    //                                         <li>Several A and up vehicles buffed</li>
    //                                         <li>CVPIs nerfed</li>
    //                                         <li>Electric cars now require refuel (think of it as a recharge ;)</li>
    //                                         <li>Boosting updated to have a set number of hacks required per class</li>
    //                                         <li>Garages updated</li>
    //                                     </ul>
    //                                 </li>
    //                             </ul>
    //                         </p>
    //                         <p><span style={{fontWeight:"700", padding:"10px 0px",display:"block"}}>We will be limiting the locations you can corner sell because the job/heist locals scuff out when you sell next to them so for now just keep away from them</span></p>
    //                     </div>
    //                 </div>
    //             )}
    // },
    // {
    //         title:"Changelog Updates - February 2023",
    //         date:"Feb, 2023",
    //         link:"/changelog",
    //         preview:"The following updates have been made in the month of February to Gomorrah. djustments to the Hospital Bed Vectors have been made,You will no longer be able to repair your vehicles at local bennys (Support your local Mechanic Shops!), ...",
    //         info:() => {
    //             return (
    //                 <div>
    //                     <div>
    //                         <h6>Change Log Updates:</h6>
    //                     </div>
    //                     <div style={{textAlign:"left",padding:"20px 0px"}}>
    //                         <p>
    //                             <span>The following updates have been made in the month of February to Gomorrah:</span>
    //                         </p>
    //                     </div>                        
    //                     <div  style={{textAlign:"left"}}>
    //                         <p>
    //                             <span>* Hospital *</span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>Adjustments to the Hospital Bed Vectors have been made </li>
    //                             </ul>
    //                         </p>
    //                         <p>
    //                             <span>* Bennys is Going out of Business *</span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>You will no longer be able to repair your vehicles at local bennys (Support your local Mechanic Shops!)</li>
    //                                 <li>Bennys will still allow you to get all the body mods, resprays and all the goods other than repairs and upgrades!</li>
    //                                 <li>With Bennys Repairs being taken out changes have been made to the following items</li>
    //                             </ul>
    //                         </p>
    //                         <p>
    //                             <span>* Repair Kit *</span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>The repair kit will now give you the same repair status that Bennys gave you (Body/Engine)</li>
    //                             </ul>
    //                         </p>
    //                         <p>
    //                             <span>* Advanced Repair Kit *</span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>The advanced repair kit will now fully restore your car but at a COST!!</li>
    //                             </ul>
    //                         </p>                            
    //                         <p>
    //                             <span style={{fontWeight:"700", padding:"10px 0px",display:"block"}}>---Patch Update---</span>                                
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>Vehicles will no longer be able to flip when turtled with the "A" and "D" keys</li>
    //                                 <li>Flipping Cars has been updated with some new features as well as it will take longer to flip the car</li>
    //                                 <li>Menus have been adjusted to match our logo colors</li>
    //                                 <li>All snow weather has been removed until next year</li>
    //                                 <li>Death animations have been changed (While escorted and dead you will still stand up for now)</li>
    //                                 <li>Fakeplate has been removed </li>
    //                                 <li>Bean Machine Job was revamped</li>
    //                                 <li>Gta Radio is removed</li>
    //                                 <li>When shot in the leg you will no longer fall over</li>
    //                                 <li>Gta Idle Camera Onfoot/InCar disabled</li>
    //                                 <li>Oxy is now functioning as it should</li>
    //                                 <li>Vehicle Engine On and Engine Off Noifications have been removed</li>
    //                                 <li>The Camera View has been changed pressing "V" only gives two camera angles rather than four</li>
    //                                 <li>When getting punched you will no longer lose your glasses or hat</li>
    //                                 <li>When trying to put someone in a car it will put the person in all seats now except for the driver seat</li>
    //                                 <li>If you have your seatbelt on in a car you can now exit the car without taking the seatbelt off</li>                                
    //                             </ul>
    //                         </p>
    //                     </div>
    //                 </div>
    //             )}
    //     },
    //     {
    //         title:"Changelog Updates - January 2023",
    //         date:"Jan 26, 2023",
    //         link:"/changelog",
    //         preview:"he following updates have been made in the month of  January to Gomorrah. Garages have now been fixed so that cars can no longer be transferred between them (if you park it somewhere you have to go back there to get it), A key bind for neon lights in...",
    //         info:() => {
    //             return (
    //                 <div>
    //                     <div>
    //                         <h6>Change Log Updates:</h6>
    //                     </div>
    //                     <div style={{textAlign:"left",padding:"20px 0px"}}>
    //                         <p>
    //                             <span>The following updates have been made in the month of  January to Gomorrah:
    //                                 <br/>Want to add your characters story in the wiki about our server? Check it out here!<br/>
    //                                 <a data-hook='linkViewer' href='https://gomorrah-rp.fandom.com/wiki/Gomorrah_RP_Wiki' target='_blank' rel='noopener noreferrer'>https://gomorrah-rp.fandom.com/wiki/Gomorrah_RP_Wiki</a>
    //                             </span>
    //                         </p>
    //                     </div>
    //                     <div  style={{textAlign:"left"}}>
    //                         <p>
    //                             <span>
    //                                 ^^ This will begin being updated soon as there are stories always being built in our lovely city.<br/>
    //                                 <span style={{fontWeight:"700", padding:"10px 0px",display:"block"}}>---FIXES &amp; REWORKS---</span>
    //                                 <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                     <li>Garages have now been fixed so that cars can no longer be transferred between them (if you park it somewhere you have to go back there to get it)</li>
    //                                     <li>A key bind for neon lights in vehicles has been created</li>
    //                                     <li>The Paintball Arena at Maze Bank Arena has been set up, teams of Red Vs. Blue with multiple map styles, weapon choices and team styles can be created for some fun</li>
    //                                     <li>Benny's pricing has been adjusted to scale more with damage than a flat base price.</li>
    //                                     <li>DOJ have now been granted access to MDW profiles and licensing (citizens are asked to go to DOJ at the court house now to be issued any kind of licenses)</li>
    //                                 </ul>                                    
    //                                 **Please keep in mind that as we roll into the last month before our anticipated launch date that Admins and Devs alike will be rather busy balancing both server work behind the scenes and their real lives, DO NOT message them directly about issues and please submit a ticket in the discord about any issues that arise. We will handle them as quickly and efficiently as possible.  --- Thank you, Gomorrah Staff**
    //                             </span>
    //                         </p>
    //                     </div>
    //                 </div>
    //             )}
    //     },
    //     {
    //         title:"Changelog Updates - Gomorrah 12/2022",
    //         date:"Dec 15, 2022",
    //         link:"/changelog",
    //         preview:"The following updates have been made in the month of December to Gomorrah. See all people employed in your business - fire people who are not awake - removed storage from management menu - removed money management from menu, Nancy will now see you ...",
    //         info:() => {
    //             return (
    //                 <div>
    //                     <div>
    //                         <h6>Change Log Updates:</h6>
    //                     </div>
    //                     <div style={{textAlign:"left",padding:"20px 0px"}}>
    //                         <p>
    //                             <span>The following updates have been made in the month of December to Gomorrah:</span>
    //                         </p>
    //                     </div>                        
    //                     <div  style={{textAlign:"left"}}>
    //                         <p>
    //                             <span>Business management:</span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>See all people employed in your business - fire people who are not awake - removed storage from management menu - removed money management from menu </li>
    //                             </ul>
    //                         </p>
    //                         <p>
    //                             <span>Prison: </span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>Nancy will now see you - if you pass out in the prison you will be taken to medical</li>
    //                             </ul>
    //                         </p>
    //                         <p>
    //                             <span>State: </span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>Passive income from the state has stopped and receipt payment now come from business accounts</li>
    //                             </ul>
    //                         </p>
    //                         <p>
    //                             <span>Stress increase: </span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>Logic has been changed whilst driving.</li>
    //                             </ul>
    //                         </p>
    //                         <p>
    //                             <span>Racing: </span>
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>Proximity to checkpoint lowered from 25 to 15 to trigger  phasing added to all races not just competitions</li>
    //                             </ul>
    //                         </p>
    //                         <p>
    //                             <span style={{fontWeight:"700", padding:"10px 0px",display:"block"}}>---FIXES &amp; REWORKS---</span>                                
    //                             <ul style={{listStyle:"disc outside",marginLeft:"50px"}}>
    //                                 <li>Fixed issue for that petrol cans have infinite ammo<br/> ammo increased per item</li>
    //                                 <li>Work Benches have been fixed and replaced in all warehouses including at Madrazo's</li>
    //                             </ul>
    //                         </p>
    //                     </div>
    //                 </div>
    //             )}
    //     },
    //     {
    //         title:"Official Rules of Gomorrah:",
    //         date:"Dec 2, 2022",
    //         link:"/rules",
    //         info:""
    //     }
    ]    
};

export default ChangeLogs;