import React from 'react';
import {Outlet} from 'react-router-dom';

const Contact = () => {
    return (
        <div> 
            <section className="intro-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> 
                            <div style={{padding:"30px 0px"}}>
                                <h1 className="title" style={{display:"block",color:"#F29423",fontSize:"50px"}}>Get in touch with us!</h1>
                            </div>                            
                        </div>
                    </div>                    
                </div>                                
            </section>
            <section className="blog-section spad">
                <div className="container">              
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto"}}>
                                <p style={{display:"block",margin:"auto",color:"#ffffff"}}>
                                Like what you see?
                                </p>
                            </div>
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto"}}>
                                <p style={{display:"block",margin:"auto",color:"#ffffff"}}>
                                Why not support the cause and check out our Patreon!
                                </p>
                            </div>
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto"}}>
                                <p style={{display:"block",margin:"auto",padding:"15px 0",color:"#ffffff"}}>
                                Exclusive rewards for supports like behind the scenes looks at art concepts for clothing/ gang sprays, Custom Digital Postcards exclusive to Gomorrah RP and more!
                                </p>
                            </div>
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto"}}>
                                <p style={{display:"block",margin:"auto"}}>
                                <img src="https://static.wixstatic.com/media/39d694_d57a6ff025e545fcb32c561da27f7b6e~mv2.png/v1/fill/w_478,h_129,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Patreon-logo-2013.png" alt="Patreon-logo-2013.png" style={{width:'400px',height:'auto',background:"#ffffff",borderRadius:"20px"}}/>
                                </p>
                            </div>
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto"}}>
                                <p style={{display:"block",margin:"auto",padding:"15px 0"}}>                                
                                <a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"261px",color: "#fff",backgroundColor: "rgb(165, 7, 134)",borderColor: "rgb(165, 7, 134)"}} href="https://www.patreon.com/GomorrahRPServer"><span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>Check it out!</span></a>
                                </p>
                            </div>
                        </div>
                        <div style={{borderTop:"2px solid #6c757d",width:"60%",margin:"30px auto"}}></div>
                        <div className="col-md-12">
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto"}}>
                                <p style={{display:"block",margin:"auto",color:"#ffffff"}}>
                                FOLLOW US ON TWITTER:
                                </p>
                            </div>
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto",padding:"20px 0px"}}>
                                <p style={{display:"block",margin:"auto"}}>                                
                                    <a href="https://twitter.com/GomorrahRp" className="link" style={{position: "relative",height: "20px",padding: "5px 12px 5px 12px",background: "#1d9bf0",color: "#ffffff",borderRadius: "9999px",fontWeight: "500",cursor: "pointer"}}><i className="ti-twitter-alt" style={{color: "#ffffff",padding:"0px 10px 0px 0px",verticalAlign:"middle"}}></i>Follow</a>                                    
                                </p>
                            </div>
                        </div>
                        <div style={{borderTop:"2px solid #6c757d",width:"60%",margin:"30px auto"}}></div>
                        <div className="col-md-12">
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto"}}>
                                <p style={{display:"block",margin:"auto",color:"#ffffff"}}>
                                Join The Discord!
                                </p>
                            </div>
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto",padding:"20px 0px"}}>
                                <p style={{display:"block",margin:"auto"}}>
                                    <a data-testid="linkElement" href="https://discord.gg/pNAewFRcdC" target="_blank" rel="noreferrer noopener" className="oRtuWN">                                    
                                        <img alt="Discord     " fetchpriority="high" src="https://static.wixstatic.com/media/11062b_5786213245914dde873f6b286a16854c~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/11062b_5786213245914dde873f6b286a16854c~mv2.png" style={{width:"30px",height:"30px"}}/>                                    
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div style={{borderTop:"2px solid #6c757d",width:"60%",margin:"30px auto"}}></div>
                        <div className="col-md-12">
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto"}}>
                                <p style={{display:"block",margin:"auto",color:"#ffffff"}}>
                                Join The Fandom!
                                </p>
                            </div>
                            <div className="row align-items-center" style={{maxWidth:"400px",width:"70%",margin:"auto",padding:"20px 0px"}}>
                                <p style={{display:"block",margin:"auto"}}>
                                    <a activestyle="true" className="sc-jTQDnj fcFGVL btn btn-primary rounded w-lg btn-lg my-1" style={{width:"261px",color: "#fff",backgroundColor: "rgb(165, 7, 134)",borderColor: "rgb(165, 7, 134)"}} href="https://gomorrah-rp.fandom.com/wiki/Gomorrah_RP_Wiki" target="_blank" rel="noreferrer noopener">                                    
                                    <span style={{display:"inline-block",fontFamily:"Arial",fontWeight:"700"}}>Fandom Wiki</span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Outlet />
        </div>        
    );
};

export default Contact;