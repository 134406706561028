import React, { useEffect, useState } from 'react';
import './App.css';
import Navbar from './components/Navbar/index';
import { HashRouter as Router, Routes, Route, Redirect, useLocation, Navigate } from 'react-router-dom';
import Home from './pages/index';
import About from './pages/about';
import Rules from './pages/rules';
import Gallerie from './pages/gallerie';
import Team from './pages/team';
import Documents from './pages/documents';
import Contact from './pages/contact';
import ChangeLog from './pages/changelog';
import SectionFooter from './components/Sections/footer';

function ReloadRoute() {
  const location = useLocation();
  const [is404, setIs404] = useState(false);

  useEffect(() => {
    if (is404) {
      window.location.reload();
    }
  }, [is404]);

  useEffect(() => {
    if (location.pathname === '/404') {
      setIs404(true);
    } else {
      setIs404(false);
    }
  }, [location]);

  return null;
}

function Redirect404() {
  const location = useLocation();
  const [is404, setIs404] = useState(false);

  useEffect(() => {
    if (location.pathname === '/404') {
      setIs404(true);
    } else {
      setIs404(false);
    }
  }, [location]);

  if (is404) {
    return <Navigate to="/" />;
  } else {
    return null;
  }
}

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <ReloadRoute />
        <Redirect404 />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/rules' element={<Rules />} />
          <Route path='/documents' element={<Documents />} />
          <Route path='/gallery' element={<Gallerie />} />
          <Route path='/team' element={<Team />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/changelog' element={<ChangeLog />} />
          <Route path='/404' element={<h1>404 Page not found</h1>} />
          <Route path='*' element={<Navigate to="/404" />} />
        </Routes>
      </Router>
      <SectionFooter />
    </div>
  );
}

export default App;
