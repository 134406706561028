import ChangeLogs from "../data/ChangeLog";
import {NavLink, NavBtn} from '../Navbar/NavbarElements';

const SectionCommunityUpdate = () => {
    return (
        <section className="blog-section spad">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-11 col-md-10">
                        <div className="section-title text-white">
                        <h5 className="section-title text-center mb-6">Community Updates:</h5> 
                        </div>					

                        {ChangeLogs.items.map((item, index) => (
                            <div key={index} className="col-sm-12 col-md-11 blog-item">
                                <div className="blog-thumb" style={{borderRadius:"9999px"}}>
                                    <img src="./imgs/android-chrome-384x384.png" width="362" alt="" style={{borderRadius:"9999px"}}/>
                                </div>
                                <div className="blog-text text-box text-white" style={{padding:"5px"}}>
                                    <div className="top-meta">{item.date}</div>
                                    <h3>{item.title}</h3>
                                    <p>{item.preview}</p>
                                    <NavLink to={item.link + "?i=" + index} className="read-more">Read More  <img src="./imgs/double-arrow.png" width={"13"} height={"23"} style={{width:"13px",height:"23px",maxHeight:"23px",maxWidth:"13px"}}/></NavLink>
                                </div>                            
                            </div>                         
                        ))}  
                    </div>        									
                </div>
            </div>
        </section>         
    );
};

export default SectionCommunityUpdate;