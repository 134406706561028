import React from 'react';
import {Outlet} from 'react-router-dom';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import gallery_structure from '../components/data/gallery_structure';
import ChildImagesItem from '../components/Gallery/ChildImagesItem';
import $ from 'jquery';

const Gallerie = () => {
    const images = {children:[]};
    function getPictureArray(fileData, path){
        const PictureArray = [];
        for (let i = 0; i < fileData.length; i++) {
            PictureArray.push('../imgs/Galleries/' + path + '/' + fileData[i].name);
        }
        return PictureArray;
    }
    function renderPictureItems(fileData, name, parentName){  
        images.children = [];      
        const imagesArray = getPictureArray(fileData.children, parentName + '/' + name);
        images.children = <ChildImagesItem images={imagesArray}/>;
    }
    return (
        <div> 
            <section className="intro-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> 
                            <div style={{padding:"30px 0px"}}>
                                <h1 className="title" style={{display:"block",color:"#F29423",fontSize:"50px"}}>Our Gallery</h1>
                            </div>                            
                        </div>
                    </div>                    
                </div>                                
            </section>
            <section className="blog-section spad">
                <div className="container">              
                    <div className="row">
                        <div className="col-md-12">
                            <Accordion>                                                                
                                {gallery_structure.map((parentItem, index) => (                                                       
                                    <AccordionItem key={parentItem.name} header={parentItem.name}>
                                        <Accordion>
                                            {parentItem.children.map((subItem, index) => (                                                
                                                <AccordionItem key={parentItem.name+'_'+subItem.name} header={subItem.name} onClick={renderPictureItems(subItem, subItem.name, parentItem.name)}>
                                                    <div id={parentItem.name + '_' + subItem.name + '_container'}>
                                                       {images.children} 
                                                    </div>                                                   
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    </AccordionItem>                
                                ))}  
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
            <Outlet />
        </div>        
    );
};

export default Gallerie;