import React from 'react';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import NewReleasesData from '../data/NewRelease';

const Completionist = () => <h6 className="box-title" style={{color: "#A50786"}}>{NewReleasesData.items[0].completionText}</h6>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>
      <span style={{padding:"5px",display:'inline-block',color:'rgb(165, 7, 134)',fontFamily:'Arial'}}>{days}<br/><span style={{color:'rgb(110 7 165)'}}>Days</span></span> <span style={{display:'inline-block',padding:'5px',color:'rgb(165, 7, 134)',fontFamily:'Arial'}}>{hours}:<br/><span style={{color:'rgb(110 7 165)'}}>Hours</span></span><span style={{display:'inline-block',padding:'5px',color:'rgb(165, 7, 134)',fontFamily:'Arial'}}>{minutes}:<br/><span style={{color:'rgb(110 7 165)'}}>Minutes</span></span><span style={{display:'inline-block',padding:'5px',color:'rgb(165, 7, 134)',fontFamily:'Arial'}}>{seconds}<br/><span style={{color:'rgb(110 7 165)'}}>Seconds</span></span>      
    </span>;
    }
};

const CountDownElement = (Props) => {
    return (
    
        <Countdown date={NewReleasesData.items[0].date} renderer={renderer}/>
    
    );
};

export default CountDownElement;