import React, { useState } from 'react';
import {Outlet} from 'react-router-dom';
import CountDown from '../components/Countdown/Countdown';
import SectionAbout from '../components/Sections/about';
import SectionLifeChoices from '../components/Sections/lifeChoices';
import SectionCommunityUpdate from '../components/Sections/communityUpdate';
import NewReleasesData from '../components/data/NewRelease';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import MyCalendar from '../components/Calendar/Calendar';
import Announcements from '../components/data/Annoncement';



const Home = () => {
    const [value, onChange] = useState(new Date());

    return (
        <div> 
            <section className="intro-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="intro-text-box text-box text-white">
                                <div className="top-meta" style={{textAlign:"center", fontSize:"45px"}}><i className="ti-target"></i></div>
                                <h6 className="box-title" style={{textAlign:"center",color: "#A50786"}}>{NewReleasesData.items[0].title}</h6>
                                <p><br/><br/>{NewReleasesData.items[0].descriptionLine1}<br/>{NewReleasesData.items[0].descriptionLine2}<br/><br/></p>
                                <div style={{textAlign:"center"}}>                    
                                <CountDown />                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="intro-text-box text-box text-white">
                                <div className="top-meta" style={{textAlign:"center", fontSize:"45px"}}><i className="ti-flag-alt-2"></i></div>
                                <h6 className="box-title" style={{textAlign:"center",color: "#A50786"}}>{Announcements.items[0].title + ' ' +  Announcements.items[0].date}</h6>
                                <p><br/><br/>{Announcements.items[0].Text}</p>                                
                            </div>
                        </div>
                        <div className="col-md-4 mobile-hidden">
                            <div className="intro-text-box text-box text-white">
                                <div className="top-meta" style={{textAlign:"center", fontSize:"45px"}}><i className="ti-calendar"></i></div>                                
                                <h6 className="box-title" style={{textAlign:"center",color: "#A50786"}}>What's to come</h6>
                                <div style={{padding:"10px"}}>
                                    <MyCalendar/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>       
            <SectionCommunityUpdate/>   
            <SectionLifeChoices/>             
            <SectionAbout/>                    
            <Outlet/>
        </div>
    );
};

export default Home;