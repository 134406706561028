import {FaBars} from 'react-icons/fa';
import { NavLink as Link} from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
    min-height: 70px;
    max-height: 700px;
    margin: auto;
    width: 80%;
    z-index: 9999;
    padding: 0px 10px;
    height: 100%;
`;

export const NavTop = styled.nav`
    margin: auto;
    width: 80%;
    z-index: 9999;
    padding: 0px 10px;
    float: left;
`;

export const NavLink = styled(Link)`
    display:flex;
    align-item:center;
    margin:0 5px;
    font-weight:700;
    font-size:14px;
    letter-spacing:1.5px;
    color:#fff;
    text-shadow:#391575 2px 2px 3px;
    text-decoration:none!important;
    padding: 0.5rem 0.7rem;
    cursor:pointer;
    &.active{
        color:#b01ba5!important;
        text-shadow:#391575 2px 2px 3px;
        font-weight: 900;
        font-size: 16px;
    }  
    &.hover{
        color:#b01ba5!important;
        text-shadow:2px 2px 3px rgba(255,255,255,0.7);
    } 
`;

export const Bars = styled(FaBars)`
    display:none;
    color:#808080;
    @media screen and (max-width: 768px){
        display: block;
        position: fixed;
        color:#ffffff;
        top: 5px;
        left: 50px;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
        z-index: 99999999; 
    }
`;

export const NavMenu = styled.div`
    
    align-item:center;
    width: 100%;
    @media screen and (max-width:768px){
        display:none;
    }
`;

export const NavBtn = styled.nav`
    display:block;
    @media screen and (max-width:768px){
        diplay:none;
    }
`;

export const NavBtnLink = styled(Link)`
padding: 15px 20px;
color: rgb(255, 255, 255)!important;
font-weight: 500;
font-size: 14px;
letter-spacing: 1.5px;
background-color: rgb(165, 7, 134)!important;
border-color: rgb(165, 7, 134)!important;
text-decoration: none !important;
border-radius: 999999px;    
`;