const SectionAbout = () => {
    return (
        <section className="featured-section">
            <div className="featured-bg set-bg" data-setbg="./imgs/image2.jpg" style={{background:"url(./imgs/image2.jpg) center top",backgroundSize:"cover"}}></div>
            <div className="featured-box" style={{background:"#0B0E32"}}>
                <div className="text-box">
                    <div className="top-meta">2022</div>
                    <h6 className="section-subtitle mb-0">About the server....</h6>
                    <p>Welcome to Gomorrah, a FiveM GTA RP server where the dark side of humanity is unleashed. Our server is not for the faint-hearted, as we offer a world where players can indulge in their wildest and most twisted desires.</p>
                    <p>Gomorrah is powered by state-of-the-art hardware and cutting-edge software, designed to deliver an immersive and seamless gaming experience. Our custom features include a dynamic economy system that rewards players for engaging in illegal activities, as well as custom weapons, drugs, and illicit businesses.</p>
                    <p>Our community is made up of like-minded individuals who revel in chaos and anarchy, where anything goes. The staff is experienced and dedicated to keeping the game exciting by introducing new challenges and creating unique storylines for players to follow.</p>
                    <p>Our server is a lawless land where players can rise to the top of the criminal underworld by any means necessary. The competition is fierce, and only the strong survive.</p>
                    <p>Gomorrah is not for the weak-willed or the easily offended. Join us today and unleash your inner demon in the twisted world of GTA RP. Will you be the one to reign supreme in this den of iniquity?</p>
                </div>
            </div>
        </section>
        
    );
};

export default SectionAbout;